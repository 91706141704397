.mb-2 {
  margin-bottom: 0.5rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.-mt-16 {
  margin-top: -4rem;
}

.mt-4 {
  margin-top: 1rem;
}

.hide {
  display: none;
}

.fulfillment-line-item[disabled] {
  opacity: 0.6;

  font-size: 60%;
}

.header-logo {
  max-width: 250px;
  max-height: 70px;
}

.Polaris-Page-Header {
  padding-top: 1rem !important;
}

.Polaris-TextField__Input {
  line-height: 1.4rem !important;
  min-height: 2.6rem !important;
}

.Polaris-Select__Content {
  line-height: 1.4rem !important;
  min-height: 2.6rem !important;
}

.full-fulfillment {
  .Polaris-DataTable__Cell--firstColumn .Polaris-Stack__Item:nth-child(2){
    max-width: 75% !important;
  }
}

.partial-fulfillment {
  .Polaris-DataTable__Cell--firstColumn {
    width: 70%;
  }
  .Polaris-DataTable__Cell--firstColumn .Polaris-Stack__Item:nth-child(2){
    max-width: 75% !important;
  }
}

.fulfullment-item {
  max-width: 120px;

  .Polaris-Connected .Polaris-TextField__Input {
    width: 55px;
  }

  .max-quanitity .Polaris-TextField__Input {
    width: 65px;
    padding-left: 7px;
    padding-right: 7px;
  }
}

$primaryColor: #148fcb;
$secondaryColor: #6bba32;
body {
  .Polaris-Button--primary {
    background: linear-gradient(180deg,lighten($primaryColor, 3%), darken($primaryColor, 3%));
    border-color: darken($primaryColor, 6%);
    box-shadow: inset 0 1px 0 0 lighten($primaryColor, 3%), 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
    color: white;

    &.Polaris-Button--disabled {
    background: lighten($primaryColor, 30%);
    border-color: lighten($primaryColor, 20%);
  }

  &:hover {
    background: linear-gradient(180deg,lighten($primaryColor, 4%), darken($primaryColor, 6%));
    border-color: darken($primaryColor, 6%);
    }
  }

  .Polaris-TextField__Backdrop::after {
    border: 0.2rem solid $primaryColor;
  }

  .Polaris-Button--plain {
    color: darken($primaryColor, 5%);
  }

  .green-button {
    .Polaris-Button {
      background: linear-gradient(180deg,lighten($secondaryColor, 3%), darken($secondaryColor, 3%));
      border-color: darken($secondaryColor, 6%);
      box-shadow: inset 0 1px 0 0 lighten($secondaryColor, 3%), 0 1px 0 0 rgba(22, 29, 37, 0.05), 0 0 0 0 transparent;
      color: white;

      &.Polaris-Button--disabled {
      background: lighten($secondaryColor, 30%);
      border-color: lighten($secondaryColor, 20%);
    }

    &:hover {
      background: linear-gradient(180deg,lighten($secondaryColor, 4%), darken($secondaryColor, 6%));
      border-color: darken($secondaryColor, 6%);
      }
    }

    .Polaris-TextField__Backdrop::after {
      border: 0.2rem solid $secondaryColor;
    }
  }

  .inventory-dashboard {
    .inventory-dashboard-table {
      .Polaris-DataTable__Cell {
        padding: 0.4rem !important;
      }

      .Polaris-TextField__Input {
        padding: 0.47rem 1.2rem !important;
      }

      .Polaris-DataTable__TableRow .Polaris-DataTable__Cell.Polaris-DataTable__Cell--verticalAlignMiddle:nth-child(4) {
        width: 125px;
      }

      .Polaris-DataTable__TableRow .Polaris-DataTable__Cell.Polaris-DataTable__Cell--verticalAlignMiddle:nth-child(5) {
        width: 270px;
      }
    }
  }

  .Polaris-Modal-Footer  {
    .content {
      min-width: 400px;
    }

    .Polaris-Stack__Item {
      flex: 1 1 auto;
    }

    .Polaris-ButtonGroup {
      justify-content: flex-end;
    }
  }

  .badge {
    padding: 0.2rem .4rem;
    word-break: break-word;
    line-height: 1;
    border-radius: 3px;


    &.info { background-color: #ffc107; }
    &.danger { background-color: #dc3545; color: #fff; }
    &.success { background-color: #8aff90; }
    &.neutral { background-color: #e7e7e7; }
  }

  .inline-block { display: inline-block;}
  .text-center { text-align: center;}
  .text-right { text-align: right;}
  .visible-hidden { visibility: hidden; }
  .absolute { position: absolute; }
  .flex { display: flex }
  .items-center { align-items: center;}
  .justify-center { justify-content: center;}
  .flex-end { justify-content: flex-end; }
  .flex-wrap { flex-wrap: wrap; }
  .p-0	{ padding: 0; }
  .p-1	{ padding: 0.25rem; }
  .p-2	{ padding: 0.5rem; }
  .p-3	{ padding: 0.75rem; }
  .p-4	{ padding: 1rem; }
  .p-5	{ padding: 1.25rem; }
  .p-6	{ padding: 1.5rem; }
  .p-8	{ padding: 2rem; }
  .p-10	{ padding: 2.5rem; }
  .pl-0	{ padding: 0; }
  .pl-1	{ padding: 0.25rem; }
  .pl-2	{ padding: 0.5rem; }
  .pl-3	{ padding: 0.75rem; }
  .pl-4	{ padding: 1rem; }
  .pl-5	{ padding: 1.25rem; }
  .pl-6	{ padding: 1.5rem; }
  .pl-8	{ padding: 2rem; }
  .pl-10	{ padding: 2.5rem; }
  .mx-auto	{ margin-left: auto; margin-right: auto; }
  .max-w-md	{ max-width: 28rem; }
  .max-w-xl	{ max-width: 36rem; }

  .inventory-table {
    margin-top: 5rem;
    td, th {  padding: .75rem; border: none }
    tr:hover {
      background: #f9fafb;
    }
  }

  .centered_loading_wrapper {
    position: relative;

  }


  /* invisible overlay */
  .centered_loading_wrapper.is_loading::before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0);
    z-index: 98;
  }
  .centered_loading_wrapper.is_loading::after {
    content: "";
    position: absolute;
    top: 42%;
    left: 49%;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 30px;
    z-index: 99;
    background: url("data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjAgMjAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuMjI5IDEuMTczYTkuMjUgOS4yNSAwIDEgMCAxMS42NTUgMTEuNDEyIDEuMjUgMS4yNSAwIDEgMC0yLjQtLjY5OCA2Ljc1IDYuNzUgMCAxIDEtOC41MDYtOC4zMjkgMS4yNSAxLjI1IDAgMSAwLS43NS0yLjM4NXoiIGZpbGw9IiM5MTlFQUIiLz48L3N2Zz4K");
    animation-name: spin;
    animation-iteration-count: infinite;
    animation-duration: 0.7s;
    animation-timing-function: linear;

  }

  @keyframes spin {
    from { transform:rotate(0deg); }
    to { transform:rotate(360deg); }
  }

  .blur {
    -webkit-filter: blur(3px);
    -moz-filter: blur(3px);
    -o-filter: blur(3px);
    -ms-filter: blur(3px);
    filter: blur(3px);
  }
}

.inventory-page {
  .Polaris-Page.Polaris-Page--fullWidth {
    max-width: 1500px;
  }

  .inventory-table-date-col {
    width: 130px;

  }

  .Polaris-Labelled__Error {
    position: absolute;
  }

  .incoming-expected-col {
    width: 200px !important;
  }

  .error-incoming-expected .react-datepicker__input-container {
    background-color: #fbeae5;
    input {
      &:focus {
        border-color: transparent;
        box-shadow: 0 0 0 0.2rem #bf0711;
      }
    }
  }

  .tall-row {
    td {
      padding-bottom: 20px
    }
  }

  .date-clear-btn {
    padding: 0;
    border: 0;
    border-radius: 50%;
    background: transparent;
    outline: none;
    transform: scale(0.7);
    cursor: pointer;

    &:focus {
      box-shadow: 0 0 0pt 1pt $primaryColor;
    }
  }

  .react-datepicker-popper {
    z-index: 99;
  }

  .react-datepicker {
    font-size: 1em;
  }
  .react-datepicker__header {
    padding-top: 0.8em;
  }
  .react-datepicker__month {
    margin: 0.4em 1em;
  }
  .react-datepicker__day-name, .react-datepicker__day {
    width: 1.9em;
    line-height: 1.9em;
    margin: 0.166em;
  }
  .react-datepicker__current-month {
    font-size: 1em;
  }
  .react-datepicker__navigation {
    top: 1em;
    line-height: 1.7em;
    border: 0.45em solid transparent;
  }
  .react-datepicker__navigation--previous {
    border-right-color: #ccc;
    left: 1em;
  }
  .react-datepicker__navigation--next {
    border-left-color: #ccc;
    right: 1em;
  }
  .react-datepicker__close-icon {
    z-index: 90;
  }
  .react-datepicker__input-container {
    background: #fff;
    line-height: 1.4rem !important;
    min-height: 2.6rem !important;
    line-height: 2.4rem;
    min-width: 0;
    min-height: 3.6rem;

    input {
      font-size: 1.6rem;
      font-weight: 400;
      text-transform: initial;
      letter-spacing: initial;
      position: relative;
      z-index: 20;
      display: block;
      flex: 1 1;
      width: 100%;
      margin: 0;
      padding: 0.5rem 1.2rem;
      background: none;
      border: 0.1rem solid transparent;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      border: 1px solid #ccc;
      border-radius: 3px;
      outline: none;

      &:focus {
        border-color: transparent;
        box-shadow: 0 0 0 0.2rem $primaryColor;
      }
    }
  }
}

.red-text {
  color:  #dc3545;
}


table, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

th {
  font-weight: 700;
  text-align: left;
}